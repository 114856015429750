<template>
  <b-container
    v-if="loaded"
    fluid
  >
    <b-row class="d-flex justify-content-center p-3">
      <b-col>
        <h1>View Statistics</h1>
        <hr>
        <b-row class="mb-3">
          <b-col>
            <LineChart
              :chart-data="totalViewsPerDay.data"
              :options="totalViewsPerDay.options"
              :styles="totalViewsPerDay.styles"
            />
          </b-col>
        </b-row>
        <b-row
          v-for="(day, index) in viewsPerEventDay"
          :key="index"
          class="mb-3"
        >
          <b-col>
            <BarChart
              :chart-data="day.data"
              :options="day.options"
              :styles="day.styles"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { makeGetRequest } from '@/util'
import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'

export default {
  components: { LineChart, BarChart },
  data () {
    return {
      loaded: false,
      totalViewsPerDay: null,
      viewsPerEventDay: null
    }
  },
  methods: {
    setTotalViewsPerDay (items) {
      const totalViewsPerDayMap = items
        .map((item) => {
          return {
            date: item.date,
            views: item.events.map((event) => event.views).reduce((acc, cur) => acc + cur, 0)
          }
        })
        .reverse()
      const totalViewsPerDay = {
        styles: {
          position: 'relative',
          height: '20rem'
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            fontSize: 24,
            text: `Total Workout Views from ${totalViewsPerDayMap[0].date} - ${totalViewsPerDayMap[totalViewsPerDayMap.length - 1].date}`
          }
        },
        data: {
          labels: totalViewsPerDayMap.map((item) => item.date),
          datasets: [
            {
              data: totalViewsPerDayMap.map((item) => item.views),
              label: 'Total Views',
              borderColor: '#3e95cd',
              backgroundColor: '#36a2eb',
              fill: true
            }
          ]
        }
      }
      this.totalViewsPerDay = totalViewsPerDay
    },
    setViewsPerEventDay (items) {
      const randomColor = () => `#${Math.floor(Math.random() * 16777216).toString(16)}`
      const viewsPerEventDay = items.map((date) => (
        {
          styles: {
            position: 'relative',
            height: '30rem'
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              fontSize: 24,
              text: `Views by Workout on ${date.date}`
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          },
          data: {
            labels: date.events.map((event) => event.name.en),
            datasets: [
              {
                data: date.events.map((event) => event.views),
                label: 'Views',
                backgroundColor: Array(date.events.length).fill().map(() => randomColor())
              }
            ]
          }
        }
      ))
      this.viewsPerEventDay = viewsPerEventDay
    },
    async getItems (days = 3) {
      try {
        const url = `${this.$config.apiUrl}/view-stats?days=${days}`
        const items = await makeGetRequest(url)
        this.setTotalViewsPerDay(items)
        this.setViewsPerEventDay(items)
      } catch (error) {
        // console.error(error)
      }
    }
  },
  async created () {
    await this.getItems()
    this.loaded = true
  }
}
</script>

<style>

</style>
